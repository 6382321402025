import './App.css';
import Hero from './components/Hero/Hero.jsx';
import Program from './components/Program/Program.jsx';
import Reasons from './components/Reasons/Reasons.jsx';
import Plans from './components/Plans/Plans.jsx';
import Testimonials from './components/Testimonials/Testimonials.jsx';
import Join from './components/Join/Join.jsx';
import Footer from './components/Footer/Footer.jsx';



function App() {
  return (
    <div className="App">
         <Hero/>
         <Program/>
         <Reasons/>
         <Plans/>
         <Testimonials/>
         <Join/>
         <Footer/>
    </div>
  )
}

export default App;
